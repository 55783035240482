// Import mixins
@import 'components/mixins.scss';

.ant-picker {
  border: 0 !important;
  border-bottom: 1px solid $primary !important;
  font-size: 15px !important;
  padding: 5px 0 !important;

  @media (max-width: $sm-max-width) {
    font-size: 15px !important;
  }
}
