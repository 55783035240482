@import 'components/mixins.scss';

.left {
  background-color: $primary;
  padding: 0 !important;
}

.right {
  background-color: $white;
}

.shadowRight {
  box-shadow: inset 0px 3px 3px rgba(75, 93, 104, 0.1) !important;
}
