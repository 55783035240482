// Import mixins
@import 'components/mixins.scss';

.ant-slider {
  &-rail {
    border-radius: 50rem !important;
    height: 20px !important;
    box-shadow: 0 5px 5px rgba(75, 93, 104, 0.1) !important;
  }

  &-track {
    border-radius: 50rem !important;
    height: 20px !important;
  }

  &-step {
    height: 18px !important;
  }

  &-handle {
    width: 32px !important;
    height: 32px !important;
    margin-top: -6px !important;
  }

  &-dot {
    display: none;
  }

  &-mark-text {
    margin-top: 24px;
    font-size: 16px;
    white-space: nowrap;
  }
}
