// Import mixins
@import 'components/mixins.scss';

.ant-select {
  border: 0 !important;
  border-bottom: 1px solid $primary !important;
  font-size: 15px !important;
  padding: 5px 0 !important;

  @media (max-width: $sm-max-width) {
    font-size: 15px !important;
  }

  .ant-select-selector {
    border: 0 !important;
  }

  &-open {
    .ant-select-selection {
      border-color: $primary;
    }
  }

  &-selection {
    &:hover {
      border-color: $primary;
    }

    &-placeholder {
      color: $gray-5 !important;
    }
  }
}
