// Import mixins
@import 'components/mixins.scss';

.ant-form-item-label label {
  font-size: 18px !important;

  @media (max-width: $sm-max-width) {
    font-size: 16px !important;
  }
}

.ant-form-item-explain {
  margin-top: 8px !important;
}
