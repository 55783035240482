@import 'components/mixins.scss';

.nav {
  width: 100%;
  height: 45px;
}

.logo img {
  height: 45px;
}
