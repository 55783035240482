@import 'components/mixins.scss';

.footerInner {
  border-top: 1px solid $border;
  padding-top: rem(30);
}

p {
  font-family: 'Baloo 2', system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
    'Noto Sans', 'Liberation Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji' !important;
  /* font-family: 'Roboto', sans-serif; */
  font-size: 12px;
}
