@import 'components/mixins.scss';

/////////////////////////////////////////////////////////////////////////////////////////
/* LIST GROUP */

.list-group-item {
  border-color: $border;

  &.active {
    background: $primary;
  }
}
