@import 'components/mixins.scss';

/////////////////////////////////////////////////////////////////////////////////////////
/* POPOVERS */

.popover {
  border-color: $border;
  .popover-inner {
    border-width: rem(8);
    &:after {
      border-width: rem(7);
    }
  }
  .popover-header {
    font-weight: 600;
    background: $gray-1;
    margin: -1px;
    padding: rem(8) rem(15);
    border-radius: 3px 3px 0 0;
  }

  &.bs-popover-right .arrow {
    &:before {
      border-right-color: $border;
    }
  }
  &.bs-popover-left .arrow {
    &:before {
      border-left-color: $border;
    }
  }
  &.bs-popover-bottom .arrow {
    &:before {
      border-bottom-color: $border;
    }
  }
  &.bs-popover-top .arrow {
    &:before {
      border-top-color: $border;
    }
  }
}
